<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        企業起步走
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="">
        <p class="image_desc">圖片為「企業起步走公益平台」運動實證記者主視覺</p>
        <p class="image_desc">(圖片提供：若林創意)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          血壓異常是成年人最容易獲得的疾病之一，近年更有患者平均年齡下降的趨勢；同時血壓也是容易取得的生理指數，可以快速反應身體狀況與得知健促成效。walkii計畫從職場血壓高風險族群出發，透過數據公益的概念，建立企業起步走平台，並將數據分析後，應用於開發運動輔導模組，改善職場健康；期望達到意識養成、習慣建立與健康促進的公共利益效應。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          舉辦「企業起步走公益平台」運動實證記者會，包括廣邀各行業百人以上大企業代表參與，邀請參加此次台灣健康運動聯盟透過計畫平台舉辦之走路與血壓量測健促活動；於活動上，實行本計畫運動實證，讓與會者體驗Walkii開發出之「運動輔導模組」，實際執行超慢跑與量血壓，同時響應數據捐贈公益。參與人員包括現行企業會員至少10家企業參與，並邀請與開放民眾參與，規模人數至少150人以上。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          透過企業起步走平台收集數據分析後投入運動輔導模組開發，提供個人運動建議並輔助建立運動習慣，並對企業提供健促ESG認證。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          目前若林創意健促服務市占率已超過1%，保守估計3年內可因此計畫提升至5%市佔。以過往客戶平均每次客單價60,000計算，額外產生每年2,880萬元；此次計畫透過數據分析，產生之「運動輔導模組」，預估 3年後獲得1%市佔估算，額外產生產值960萬元所以透過此計畫建造之平台，預估能提升若林創意於未來增加3,840萬元產值。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11209',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-09.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
